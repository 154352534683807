
import './App.css';
import Landing from './pages/Landing';
import Socials from './components/socials';

function App() {
  return (
    <>
    <Landing/>
    <Socials/>
    </>
  )
}

export default App;
